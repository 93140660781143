.quill-ru {
  background-color: white !important;
  color: black !important;

  .ql-picker.ql-header {
    .ql-picker-label::before {
      content: "Абзац" !important;
    }
    .ql-picker-label[data-value="1"]::before {
      content: "Заголовок1" !important;
    }
    .ql-picker-label[data-value="2"]::before {
      content: "Заголовок2" !important;
    }
  }

  .ql-picker.ql-header {
    .ql-picker-item::before {
      content: "Абзац" !important;
    }
    .ql-picker-item[data-value="1"]::before {
      content: "Заголовок1" !important;
    }
    .ql-picker-item[data-value="2"]::before {
      content: "Заголовок2" !important;
    }
  }
  .ql-tooltip[data-mode=link]::before {
    content: "Введите ссылку" !important;
  }
  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    content: "Cохранить" !important;
  }

  .ql-snow .ql-tooltip.ql-editing {
    left: 0 !important;
    top: 0 !important;
  }
}